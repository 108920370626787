<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col>
        <b-button-group v-if="showAddButton">
          <b-button
            variant="outline-dark"
            title="Add fermentation"
            @click="addItem()"
          >
            <font-awesome-icon icon="plus" /> Create
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(dataset)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            v-if="+props.row['Amount Fermentation Remaining'] > 0"
            class="btn btn-warning btn-sm"
            @click="createDistillation(props.row.ID)"
            title="New distillation"
          >
            <strong>D</strong>
          </button>
          <button
            class="btn btn-primary btn-sm"
            @click="viewItem(props.row.ID)"
            title="View"
          >
            <font-awesome-icon icon="eye" />
          </button>

          <button
            class="btn btn-success btn-sm"
            @click="editItem(props.row.ID)"
            title="Edit"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row)"
            title="Delete"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
import TableCustom from '@/components/TableCustom'

export default {
  props: {
    mashId: {
      type: [String, Number],
      default: ''
    },
    showAddButton: {
      type: Boolean,
      default: true
    }
  },
  name: 'FermentationTable',
  components: {
    TableCustom
  },
  data: function () {
    return {
      dataset: {
        name: 'fermentation'
      },
      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            'ID',
            'Product Name',
            'Container Number',
            'Start Date',
            'Start Time',
            'Fermentation Time',
            'End Date',
            'End Time',
            'Amount',
            'Amount Fermentation Remaining',
            'FC Amount Remaining',
            'Brix In',
            'Brix Out',
            'Created',
            'Modified',
            'Created By',
            'Modified By',
            'Actions'
          ],
          filterable: [
            'ID',
            'Product Name',
            'Container Number',
            'Start Date',
            'Start Time',
            'Fermentation Time',
            'End Date',
            'End Time',
            'Amount',
            'Amount Fermentation Remaining',
            'FC Amount Remaining',
            'Brix In',
            'Brix Out',
            'Created',
            'Modified',
            'Created By',
            'Modified By'
          ],
          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: false,
          showCustomActions: true
        }
      }
    }
  },
  computed: {},
  mounted () {
    if (this.mashId) this.getData({ mashing_product_id: this.mashId })
  },
  methods: {
    onFilter () {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length
    },

    async getData (payload) {
      let self = this

      this.dataTable.isLoading = true

      let response = []

      try {
        response = await this.$api.post('fermentation', payload)

        self.dataTable.isLoading = false

        self.dataTable.dataSet = response

        if (response.length === 0) return

        self.$emit('loaded', self.dataTable.dataSet.length)
      } catch (error) {
        console.log(error)
        self.dataTable.isLoading = false
        self.$form.msgBoxOk('Error occured')
      }
    },
    addItem: function () {
      this.$router.push({
        name: 'Fermentation submission',
        params: {
          action: 'create',
          mashId: this.mashId
        }
      })
    },
    viewItem: function (id) {
      this.$router.push({
        name: 'Fermentation submission',
        params: {
          action: 'view',
          id: id
        }
      })
    },
    editItem: function (id) {
      this.$router.push({
        name: 'Fermentation submission',
        params: {
          action: 'edit',
          id: id
        }
      })
    },
    deleteItem: async function (item) {
      // let item = this.dataTable.dataSet.find(item => item.ID === id)
      // console.log('deleteItem:', id)
      // console.log('item:', item)
      // console.log('this.dataTable.dataSet:', this.dataTable.dataSet)
      let confirm = await this.$form.showConfirmation(
        `Fermentation #${item['Product Name']} will be deleted. Do you want to proceed?`
      )

      if (!confirm) return

      let self = this

      this.$api
        .delete(`fermentation/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message)

          self.getData()
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(response.message)
        })
    },
    createDistillation (id) {
      this.$router.push({
        name: 'Distillation submission',
        params: {
          action: 'create',
          fermentationId: id
        }
      })
    }
  },
  watch: {}
}
</script>

<style scoped></style>
